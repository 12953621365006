<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      show-search
      @reset="reset"
      v-if="searchFields.length"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #typeId="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>

        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row class="mb-2" v-if="$store.state.profile.id !== user.id">
          <b-col cols="6">
            <b-button type="button" variant="outline-secondary" @click="back">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t("button.back") }}
            </b-button>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="getData"
            >
              <feather-icon icon="RefreshCcwIcon" />
              {{ $t("button.refresh") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mx-2 my-1" v-else>
          <b-col cols="12" class="text-right">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="getData"
            >
              <feather-icon icon="RefreshCcwIcon" />
              {{ $t("button.refresh") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #thead-top="" v-if="$store.state.profile.id !== user.id">
            <b-tr class="text-center">
              <b-th :colspan="typeId === 5 ? 10 : 14">
                {{ user.name }}
              </b-th>
            </b-tr>
          </template>
          <template #cell(username)="data">
            <b-link class="link-underline" @click="viewMember(data.item.userId)"
              >{{ data.item.username }}
            </b-link>
          </template>
          <template #cell(betAmount)="data">
            <span
              :class="{
                'text-meron': data.item.betAmount < 0,
                'text-wala': data.item.betAmount >= 0,
              }"
            >
              {{ data.item.betAmount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(newWl)="data">
            <b-link
              class="link-underline"
              :class="{
                'text-meron': data.item.newWl < 0,
                'text-wala': data.item.newWl >= 0,
              }"
              @click="showBetParleyHistory(data.item, true)"
            >
              {{ data.item.newWl | currency(data.item.ccy) }}
            </b-link>
          </template>
          <template #cell(outstanding)="data">
            <b-link
              class="link-underline text-wala"
              @click="showBetParleyHistory(data.item, false)"
            >
              {{ data.item.outstanding | currency(data.item.ccy) }}
            </b-link>
          </template>
          <template #cell(wager)="data">
            <b-link
              class="link-underline"
              @click="showBetParleyHistory(data.item, false)"
            >
              {{ data.item.wager }}
            </b-link>
          </template>
          <template #cell(member)="data">
            <b-link
              class="link-underline"
              @click="viewMember(data.item.userId)"
            >
              {{ data.item.member }}
            </b-link>
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th :colspan="user.typeId == 5 ? 3 : 4"></b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.betAmount >= 0,
                    'text-meron': totalReport.betAmount < 0,
                  }"
                >
                  {{ totalReport.betAmount | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th>
                <h5 class="font-weight-bold">{{ totalReport.wager }}</h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="√"
                  :class="{
                    'text-meron': totalReport.newWl < 0,
                    'text-wala': totalReport.newWl >= 0,
                  }"
                >
                  {{ totalReport.newWl | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5 class="text-wala font-weight-bold">
                  {{ totalReport.outstanding | currency(totalReport.ccy) }}
                </h5>
              </b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
    <bet-parley-history ref="betParleyHistory" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BLink,
  BRow,
  BTh,
  BTr,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import playerTableFields from "./playerTableField";
import searchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";
import BetParleyHistory from "./betParleyHistory/Index";

const ParleyReportRepository = Repository.get("parleyReport");

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    BetParleyHistory,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
    typeId(value) {
      this.setSearchFields(value);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId ||
        !!this.$route.query.startDate ||
        !!this.$route.query.toDate
      );
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.id;
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || null,
        typeId: Number(this.$route.query.typeId) || null,
        startDate: this.$route.query.startDate || moment().format("YYYY-MM-DD"),
        toDate: this.$route.query.toDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
        search: this.$route.query.search || "",
      },
      totalReport: {},
      avatarText,
      user: this.$store.state.profile,
      searchFields: [],
      userTypeId: null,
    };
  },
  mounted() {
    this.getData(this.params.page);
    if (this.$store.state.profile.typeId) {
      this.changeCol(this.$store.state.profile.typeId);
      this.setSearchFields(this.$store.state.profile.typeId);
    }
  },
  methods: {
    changeCol(value) {
      if (value === 5) {
        this.fields = [...playerTableFields];
      } else {
        this.fields = [...tableFields];
      }
    },
    setSearchFields(userTypeId) {
      let typeSearch = {
        key: "typeId",
        label: "field.type",
        type: "radio",
        cols: 6,
      };
      if (userTypeId === 1 || userTypeId === 8) {
        typeSearch["options"] = [
          {
            value: 2,
            text: "breadcrumb.company",
          },
          {
            value: 3,
            text: "breadcrumb.senior",
          },
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 2) {
        typeSearch["options"] = [
          {
            value: 3,
            text: "breadcrumb.senior",
          },
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 3) {
        typeSearch["options"] = [
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 4) {
        typeSearch["options"] = [
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 5) {
        typeSearch["options"] = [
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      }
      this.searchFields = [...searchInputs, typeSearch];
    },
    getUserTypeId() {
      if (this.$store.state.profile.typeId) {
        userTypeId = this.$store.state.profile.typeId;
      } else {
        userTypeId = null;
      }
    },
    back() {
      this.params.userId = this.user.parentId;
      this.params.page = 1;
      this.updateQuerySting();
      this.getData();
    },
    viewMember(userId) {
      this.params.userId = userId;
      this.params.page = 1;
      this.updateQuerySting();
      this.getData();
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.userId = null;
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      ParleyReportRepository.index({
        ...this.params,
      })
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {};
          this.items = [...data.list];
          this.user = {
            ...data.user,
          };
          if (this.user.typeId) {
            this.changeCol(this.user.typeId);
          }
          this.total = data.total;
          this.loading = false;
          this.getTotal();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      ParleyReportRepository.total({
        ...this.params,
      })
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
    showBetParleyHistory(data, parleyResult = true) {
      this.$refs.betParleyHistory.show(data, parleyResult, this.params);
    },
  },
  setup() {
    let fields = [...tableFields];
    return {
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
