export default [
  {
    key: 'channelId',
    label: 'field.channel',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.date',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'invoiceNo',
    label: 'field.invoiceNo',
  },
  {
    key: 'amount',
    label: 'field.betAmount',
  },
  {
    key: 'payout',
    label: 'field.odd',
  },
  {
    key: 'estPayoutAmount',
    label: 'field.estPayoutAmount',
  },
  {
    key: 'gameType',
    label: 'field.gameType',
    thClass: 'w-50px',
  },
  {
    key: 'type',
    label: 'field.type',
  },
  {
    key: 'parleyResult',
    label: 'field.result',
  },
  {
    key: 'winAmount',
    label: 'field.winLoss',
  },
  {
    key: 'paybackAmount',
    label: 'field.payback',
  },
  // {
  //     key: 'actions',
  //     label: 'general.action',
  // },
];
