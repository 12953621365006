export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    label: 'field.name',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'betAmount',
    sortable: false,
    sortField: '',
    label: 'field.betAmount',
    tdClass: 'text-right',
  },
  {
    key: 'wager',
    sortable: false,
    sortField: '',
    label: 'field.wager',
  },
  {
    key: 'newWl',
    sortable: false,
    sortField: '',
    label: 'field.newWinLoss',
    tdClass: 'text-right',
  },
  {
    key: 'outstanding',
    sortable: false,
    sortField: '',
    label: 'field.outstanding',
    tdClass: 'text-right',
  },
];
