<template>
  <b-modal
    id="modal-parley-report"
    ok-variant="outline-secondary"
    :ok-title="$t('button.close')"
    centered
    :title="$t('breadcrumb.parleyReport')"
    size="xl"
    :hide-footer="true"
    @hide="isShow = false"
  >
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      show-search
      v-if="searchFields.length"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(amount)="data">
            {{ data.item.amount | currency(data.item.ccy) }}
          </template>
          <template #cell(selectedBet)="data">
            <span
              v-if="data.item.selectedBet"
              :class="'text-capitalize text-' + data.item.selectedBet"
            >
              {{ data.item.selectedBet }}
            </span>
          </template>
          <template #cell(parleyResult)="data">
            <span
              v-if="data.item.parleyResult || data.item.parleyResult == 0"
              :class="
                'text-capitalize text-' +
                (data.item.parleyResult ? 'draw' : 'meron')
              "
            >
              {{
                data.item.parleyResult ? $t("general.win") : $t("general.loss")
              }}
            </span>
          </template>
          <template #cell(estPayoutAmount)="data">
            <span>
              {{ data.item.estPayoutAmount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(type)="data">
            <b-link class="link-underline" @click="showDetail(data)">
              {{ data.item.type }}
            </b-link>
          </template>
          <template #cell(winAmount)="data">
            <span
              :class="{
                'text-wala': data.item.winAmount >= 0,
                'text-meron': data.item.winAmount < 0,
              }"
            >
              {{ data.item.winAmount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(paybackAmount)="data">
            <span
              :class="{
                'text-wala': data.item.paybackAmount >= 0,
                'text-meron': data.item.paybackAmount < 0,
              }"
            >
              {{ data.item.paybackAmount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-b-tooltip.hover
                :title="$t('button.view')"
                variant="primary"
                pill
                size="sm"
                @click="showDetail(data)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="4"></b-th>
              <b-th class="text-center font-weight-bold">
                <h5>
                  {{ totalReport.totalBetAmount | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th class="text-center font-weight-bold">
                <h5>{{ total }}</h5>
              </b-th>
              <b-th></b-th>
              <b-th class="text-center">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.totalWinAmount >= 0,
                    'text-meron': totalReport.totalWinAmount < 0,
                  }"
                >
                  {{ totalReport.totalWinAmount | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-center">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.totalPaybackAmount >= 0,
                    'text-meron': totalReport.totalPaybackAmount < 0,
                  }"
                >
                  {{
                    totalReport.totalPaybackAmount | currency(totalReport.ccy)
                  }}
                </h5>
              </b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
    <parley-report-detail ref="parleyReportDetail"></parley-report-detail>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BLink,
  BTr,
  BTh,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableField";
import SearchInput from "./searchInput";
import { avatarText } from "@core/utils/filter";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import ParleyReportDetail from "@/views/parleyReport/parleyDetail/Index";
import moment from "moment";

const ParleyReportRepository = Repository.get("parleyReport");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BLink,
    BTr,
    BTh,
    ParleyReportDetail,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId ||
        !!this.$route.query.startDate ||
        !!this.$route.query.toDate
      );
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.id;
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: 1,
        order: null,
        sort: null,
        userId: null,
        startDate: null,
        toDate: null,
        period: null,
        search: null,
      },
      user: this.$store.state.profile,
      avatarText,
      totalReport: {},
    };
  },
  methods: {
    show(data, parleyResult, params) {
      this.$bvModal.show("modal-parley-report");
      this.isShow = true;
      this.params.userId = data.userId;
      this.params.parleyResult = parleyResult;
      this.params.startDate = params.startDate;
      this.params.toDate = params.toDate;
      this.params.period = params.period;
      this.list();
    },
    hide() {
      this.$bvModal.hide("modal-parley-report");
      this.items = [];
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.getData();
    },
    getData() {
      this.loading = true;
      ParleyReportRepository.listParleyHistory({
        ...this.params,
      })
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {};
          this.items = [...data.list];
          this.total = data.total;
          this.getTotal();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      ParleyReportRepository.totalParleyHistory({
        ...this.params,
      }).then((response) => {
        const data = response.data.data;
        this.totalReport = {
          ...data,
        };
      });
    },
    showDetail(data) {
      this.$refs.parleyReportDetail.show(data.item);
    },
  },
  setup() {
    const fields = [...TableFields];
    const searchFields = [...SearchInput];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
